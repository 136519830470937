import { Flex, Link, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Flex
      as="footer"
      justifyContent="center"
      alignItems="center"
      py={4}
      mt={8} // Adjust margin as needed
      borderTop="1px solid"
      borderColor="gray.200"
      color="gray.600"
      w="full"
    >
      <Text fontSize="sm">
          © {new Date().getFullYear()} Montague Labs LLC. All rights reserved.
        <Link color="blue.500" href="/terms" mr={2}>
          Terms and Conditions
        </Link>
        <Link color="blue.500" href="/privacy" mr={2}>
          Privacy Policy
        </Link>
      </Text>
    </Flex>
  );
};

export default Footer;
