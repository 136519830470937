import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import Logo2 from "../../Images/Logo2.png";
import PublicNav from "../general/PublicNav";

import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  Link,
  Checkbox,
  Image,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

function Login({ auth, errors, loginUser }) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
    errors: {},
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [auth.isAuthenticated, navigate]);

  useEffect(() => {
    if (errors) {
      setState((prevState) => ({
        ...prevState,
        errors,
      }));
    }
  }, [errors]);

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
      errors: {}, // Resetting the errors object to empty
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: state.email,
      password: state.password,
    };

    loginUser(userData, navigate);
  };

  const { email, password } = state;
  const formWidth = useBreakpointValue({ base: "full", md: "md" });

  return (
    <>
      <Flex
        direction="column"
        w="full"
        minH="100vh"
        align="center"
        justify="center"
        p={6}
        bg="gray.50"
      >
        <Flex w="400px" p="32px" bg="white" boxShadow="xl" borderRadius="lg">
          {/* Form Container */}
          <VStack display="flex" alignItems="start" w="full">
            <Button
              mb={4}
              p={0}
              style={{ textDecoration: "none", background: "none" }}
              as={Link}
              href="/"
            >
              <Image objectFit="cover" src={Logo2} alt="UI Marketplace" />
            </Button>
            <Text mb={0} fontSize="18px" fontWeight="semibold">
              Login to your account
            </Text>
            <Box as="form" w="full" onSubmit={onSubmit} noValidate>
              <VStack align="flex-start" spacing={4}>
                <Box w="full">
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Email"
                    isInvalid={errors.email || errors.emailnotfound}
                  />

                  <Box mt={2}>
                    <Text as="b" color="red.600">
                      {errors.emailnotfound}
                    </Text>
                  </Box>
                </Box>

                <Box w="full">
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={onChange}
                    placeholder="Password"
                    isInvalid={errors.password || errors.passwordincorrect}
                  />
                  <Box mt={2}></Box>
                  <Text as="b" color="red.600">
                    {errors.passwordincorrect}
                  </Text>
                </Box>

                <Button w="full" variant="primaryButton" type="submit">
                  Login
                </Button>
              </VStack>
            </Box>
            <Flex
              flexDirection="column"
              justify="flex-start"
              align="flex-start"
              mt="2"
            >
              <Text textAlign="center" fontSize="sm" color="gray.600">
                Don't have an account?{" "}
                <Link as={RouterLink} to="/signup" color="blue.500">
                  Sign up
                </Link>
              </Text>

              <Text fontSize="sm" color="gray.600">
                By continuing, you agree to UIMarketplace's{" "}
                <Link as={RouterLink} to="/terms" color="blue.500">
                  Terms and Conditions{" "}
                </Link>
                and{" "}
                <Link as={RouterLink} to="/privacy" color="blue.500">
                  Privacy Policy
                </Link>
              </Text>
            </Flex>
          </VStack>

          {/* Image Container 
        <Box w="full" h="full" display={{ base: "none", md: "block" }}>
          <Image
            src="https://cdn.dribbble.com/userupload/12477166/file/original-8d9d0c87cc353ccfd053afaad5b9264c.png?resize=752x"
            alt="Login Image"
            objectFit="cover"
            h="full"
            w="full"
            borderRadius="lg"
          />
        </Box>
        */}
        </Flex>
      </Flex>
    </>
  );
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
